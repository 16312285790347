import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Button, message, PageHeader, Select, Space, Table} from "antd";

import './ServiceSummaryTypeStatusPage.css'
import ServiceSummaryTypeStatusDetailsDialog from "./ServiceSummaryTypeStatusDetailsDialog";
import {ParkStatuses} from "../../../common/AppEnums";
import numeral from "numeral"

const { Option } = Select;

export default function ModelDowntimeReport({}) {
  let [update, setUpdate] = useState(0);
  let [loading, setLoading] = useState(false);
  let [records, setRecords] = useState([]);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState(null);
  let [cellDetails, setCellDetails] = useState(null);
  let [vehicleGroups, setVehicleGroups] = useState([]);
  let [selectedGroupId, setSelectedGroupId] = useState(null);
  let [vehicleStatusFilter, setVehicleStatusFilter] = useState([]);

  useEffect(() => {
    // setLoading(true);
    // apiClient.getServiceSummaryModelStatus({regionId: selectedRegionId, groupId: selectedGroupId})
    //   .then(res => {
    //     setRecords(res.records);
    //   })
    //   .catch(err => { message.error(err); })
    //   .finally(() => { setLoading(false); })
    setRecords([
      {
        "brand_model": "Cherry Tiggo",
        "working": 10.00,
        "parked": 11.00,
        "accident": 12.00,
        "parts_waiting": 13.00,
        "locksmith_repair_park": 14.00,
        "locksmith_repair_contractor": 15.00,
        "need_body_repair": 16.00,
        "body_repair_park": 17.00,
        "body_repair_contractor": 18.00,
        "warranty_repair": 19.00,
        "preparing": 20.00,
        "licenseless": 21.00,
        "owner_return": 22.00,
        "transfer": 23.00,
        "other": 24.00,
        "total": 87.00,
      },
      {
        "brand_model": "Geely Coolray",
        "working": 2.00,
        "parked": 3.00,
        "accident": 4.00,
        "parts_waiting": 5.00,
        "locksmith_repair_park": 6.00,
        "locksmith_repair_contractor": 7.00,
        "need_body_repair": 8.00,
        "body_repair_park": 9.00,
        "body_repair_contractor": 10.00,
        "warranty_repair": 11.00,
        "preparing": 12.00,
        "licenseless": 13.00,
        "owner_return": 14.00,
        "transfer": 15.00,
        "other": 16.00,
        "total": 77.00,
      }
    ])
  }, [update, selectedRegionId, selectedGroupId]);

  useEffect(() => {
    apiClient.getRegions({"order": "title"})
      .then(res => {
        setRegions(res.records);
      })
      .catch(err => { console.error(err) });
    apiClient.getVehicleGroups({"order": "title"})
      .then(res => { setVehicleGroups(res.records) })
      .catch(err => { console.error(err) });
  }, []);

  const dataCell = (value) => {
    return (
      <div style={{textAlign: 'center', fontSize: '0.9em'}}>{numeral(value).format('0.00')}%</div>
    )
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Простои по моделям ТС"
        extra={
          <div style={{textAlign: "right"}}>
            <Space>
              <span>{`Моделей ТС: ${records.length}`}</span>
            </Space>
          </div>
        }
      />
      <Space style={{margin: '10px 0'}}>
        <Select
          allowClear
          mode="multiple"
          placeholder="Статус"
          value={vehicleStatusFilter}
          style={{width: 300}}
          onChange={(data) => { setVehicleStatusFilter(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            ParkStatuses.filter(el => el.enabled).map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          showSearch
          allowClear
          mode={"multiple"}
          placeholder="Филиал"
          style={{width: 300}}
          onChange={(data) => { setSelectedRegionId(data); }}
          filterOption={(input, option) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {
            regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
        <Select
          allowClear
          placeholder="Группа"
          value={selectedGroupId}
          style={{width: 300}}
          onChange={(data) => { setSelectedGroupId(data); }}
        >
          {
            vehicleGroups.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
          }
        </Select>
      </Space>
      <Table
        loading={loading}
        size={'small'}
        scroll={{x: 1000}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 10,
          },
          {
            title: 'Модель ТС',
            dataIndex: 'brand_model',
            align: "center",
          },
          {
            title: 'На линии',
            dataIndex: 'working',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.working);
            },
            sorter: (a, b) => {
              return a.working - b.working;
            },
          },
          {
            title: 'В парке',
            dataIndex: 'parked',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.parked);
            },
            sorter: (a, b) => {
              return a.parked - b.parked;
            },
          },
          {
            title: 'ДТП',
            dataIndex: 'accident',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.accident);
            },
            sorter: (a, b) => {
              return a.accident - b.accident;
            },
          },
          {
            title: 'Ожидание поставки запчастей',
            dataIndex: 'parts_waiting',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.parts_waiting);
            },
            sorter: (a, b) => {
              return a.parts_waiting - b.parts_waiting;
            },
          },
          {
            title: 'Слесарный ремонт парк',
            dataIndex: 'locksmith_repair_park',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.locksmith_repair_park);
            },
            sorter: (a, b) => {
              return a.locksmith_repair_park - b.locksmith_repair_park;
            },
          },
          {
            title: 'Слесарный ремонт подрядчики',
            dataIndex: 'locksmith_repair_contractor',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.locksmith_repair_contractor);
            },
            sorter: (a, b) => {
              return a.locksmith_repair_contractor - b.locksmith_repair_contractor;
            },
          },
          {
            title: 'Требуется кузовной ремонт',
            dataIndex: 'need_body_repair',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.need_body_repair);
            },
            sorter: (a, b) => {
              return a.need_body_repair - b.need_body_repair;
            },
          },
          {
            title: 'Кузовной ремонт парк',
            dataIndex: 'body_repair_park',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.body_repair_park);
            },
            sorter: (a, b) => {
              return a.body_repair_park - b.body_repair_park;
            },
          },
          {
            title: 'Кузовной ремонт подрядчики',
            dataIndex: 'body_repair_contractor',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.body_repair_contractor);
            },
            sorter: (a, b) => {
              return a.body_repair_contractor - b.body_repair_contractor;
            },
          },
          {
            title: 'Гарантийный ремонт',
            dataIndex: 'warranty_repair',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.warranty_repair);
            },
            sorter: (a, b) => {
              return a.warranty_repair - b.warranty_repair;
            },
          },
          {
            title: 'Отдел подготовки',
            dataIndex: 'preparing',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.preparing);
            },
            sorter: (a, b) => {
              return a.preparing - b.preparing;
            },
          },
          {
            title: 'Подготовка к выдаче',
            dataIndex: 'licenseless',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.licenseless);
            },
            sorter: (a, b) => {
              return a.licenseless - b.licenseless;
            },
          },
          {
            title: 'Возврат собственнику',
            dataIndex: 'owner_return',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.owner_return);
            },
            sorter: (a, b) => {
              return a.owner_return - b.owner_return;
            },
          },
          {
            title: 'Перегон',
            dataIndex: 'transfer',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.transfer);
            },
            sorter: (a, b) => {
              return a.repair - b.repair;
            },
          },
          {
            title: 'Иное',
            dataIndex: 'other',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.other);
            },
            sorter: (a, b) => {
              return a.other - b.other;
            },
          },
          {
            title: 'Общий простой по типу ТС',
            dataIndex: 'total',
            align: "center",
            render: (text, record, index) => {
              return dataCell(record.total);
            },
            sorter: (a, b) => {
              return a.other - b.other;
            },
          },
          {
            title: "",
            align: "center",
            width: 10,
          }
        ]}
        dataSource={records}
        rowKey="brand_model"
        rowClassName={(record, index) => { return `bordered-row` }}
        pagination={{position: 'topRight', defaultPageSize: 100, pageSizeOptions: [100, 150, 200], hideOnSinglePage: true}}
        summary={(pageData) => {
          let totalTotal = 0;
          let totalWorking = 0;
          let totalParked = 0;
          let totalAccident = 0;
          let totalPartsWaiting = 0;
          let totalLocksmithRepairPark = 0;
          let totalLocksmithRepairContractor = 0;
          let totalNeedBodyRepair = 0;
          let totalBodyRepairPark = 0;
          let totalBodyRepairContractor = 0;
          let totalWarrantyRepair = 0;
          let totalLicenseless = 0;
          let totalOwnerReturn = 0;
          let totalPreparing = 0;
          let totalRransfer= 0;
          let totalOther = 0;
          pageData.forEach(({ brand_model, total, working, parked, accident, parts_waiting, locksmith_repair_park,
                              locksmith_repair_contractor, need_body_repair, body_repair_park, body_repair_contractor,
                              warranty_repair, preparing, licenseless, owner_return, transfer, other }) => {
            totalTotal += total;
            totalWorking += working;
            totalParked += parked;
            totalAccident += accident;
            totalPartsWaiting += parts_waiting;
            totalLocksmithRepairPark += locksmith_repair_park;
            totalLocksmithRepairContractor += locksmith_repair_contractor;
            totalNeedBodyRepair += need_body_repair;
            totalBodyRepairPark += body_repair_park;
            totalBodyRepairContractor += body_repair_contractor;
            totalWarrantyRepair += warranty_repair;
            totalLicenseless += licenseless;
            totalOwnerReturn += owner_return;
            totalPreparing += preparing;
            totalRransfer += transfer;
            totalOther += other;
          });
          return loading ? null : (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={-1}></Table.Summary.Cell>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{dataCell(totalWorking)}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{dataCell(totalParked)}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{dataCell(totalAccident)}</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>{dataCell(totalPartsWaiting)}</Table.Summary.Cell>
                <Table.Summary.Cell index={6}>{dataCell(totalLocksmithRepairPark)}</Table.Summary.Cell>
                <Table.Summary.Cell index={7}>{dataCell(totalLocksmithRepairContractor)}</Table.Summary.Cell>
                <Table.Summary.Cell index={8}>{dataCell(totalNeedBodyRepair)}</Table.Summary.Cell>
                <Table.Summary.Cell index={9}>{dataCell(totalBodyRepairPark)}</Table.Summary.Cell>
                <Table.Summary.Cell index={10}>{dataCell(totalBodyRepairContractor)}</Table.Summary.Cell>
                <Table.Summary.Cell index={11}>{dataCell(totalWarrantyRepair)}</Table.Summary.Cell>
                <Table.Summary.Cell index={12}>{dataCell(totalLicenseless)}</Table.Summary.Cell>
                <Table.Summary.Cell index={13}>{dataCell(totalOwnerReturn)}</Table.Summary.Cell>
                <Table.Summary.Cell index={14}>{dataCell(totalPreparing)}</Table.Summary.Cell>
                <Table.Summary.Cell index={15}>{dataCell(totalRransfer)}</Table.Summary.Cell>
                <Table.Summary.Cell index={16}>{dataCell(totalOther)}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {
        cellDetails && (
          <ServiceSummaryTypeStatusDetailsDialog
            data={cellDetails}
            onClose={() => { setCellDetails(null); }}
          />
        )
      }
    </>
  )
}